import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Title, Box, List } from "../components/Core";
import styled from "styled-components";

const BoxStyled = styled(Box)`
  .block-title {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 21px;
    font-weight: 700;
    line-height: 34px;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 15px;
  }
`;

const AssetsBestPractices = () => {
  return (
    <>
      <PageWrapper footerDark>
        <BoxStyled>
          <div className="pt-5 mt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box className="mb-5">
                  <div className="mb-5">
                    <Title variant="hero">
                      Streamline Business Processes with The Best Maintenance
                      Practices
                    </Title>
                    <h2 className="block-title mb-1">
                      Setting a New Standard of Performance
                    </h2>
                    <p>
                      How are you optimizing maintenance workflows in your
                      business?
                    </p>
                    <p>Wait, maintenance what now?.</p>
                    <p>
                      Whether you’re a business owner, maintenance manager, or a
                      little bit of both, having your workflows in order is the
                      best way for you to have every task under control. Once
                      you’ve implemented the best maintenance practices, you’ll
                      start having a lot more control over all of your
                      operations in general. Let’s see how with five concrete
                      tips on how you can streamline business processes through
                      concrete maintenance practices.
                    </p>
                  </div>
                  <div className="mb-5">
                    <Box>
                      <h1>1. Understand What You’re Doing</h1>
                    </Box>
                  </div>
                  <div className="mb-5">
                    <List>
                      <p>
                        However obvious this may sound, you need to grasp what
                        you’re currently doing. From here, you can actually go
                        and determine what needs to be improved! If you think
                        about it, understanding what you’re already doing right
                        or wrong is the only way to identify areas of
                        improvement or even leave other, more productive
                        processes as is.
                      </p>
                      <p>Let’s take a look at how you can go about it:</p>
                      <li>Point of Reference:</li>
                      <p>
                        {" "}
                        Compare all of your equipment and team performances with
                        what you want to see – A.K.A. your ultimate goals. By
                        looking at the current state of your equipment, output
                        quality, team KPIs, inventory stocking, and delivery,
                        you can actually compare the data with the goals you’ve
                        already established. If you don’t have a goal
                        established yet, be sure to create one before comparing
                        to truly outline your strategies going forward.
                      </p>
                      <li>Workflow:</li>
                      <p>
                        {" "}
                        If there’s anything you need to get as close to perfect
                        as possible, it’s workflow. Though running a tight ship
                        may seem complicated, it’s the best way to ensure
                        everything runs according to plan. For example, if you
                        have Tim working as a technician on a conveyor system,
                        you need to confirm that both of you know exactly what
                        needs to be done. Should Tim service the conveyor every
                        week, or bi-monthly? Has he even been servicing the
                        equipment as often as you thought? It’s your
                        responsibility to ensure that Tim has a clear
                        understanding of his and your organization’s goals, and
                        this can only be done by establishing a clear workflow.
                        Ultimately, a concrete workflow will help you improve
                        asset performance, structure each team’s
                        responsibilities, and therefore increase productivity
                        holistically. It’s absolutely normal to test different
                        workflows, but the main objective would be to find the
                        best course of action for your organization’s goals
                      </p>
                      <li>KPIs for the Win:</li>
                      <p>
                        {" "}
                        Always ensure you have Key Performance Indicators (KPIs)
                        set up for all the individual facets of your operations.
                        For example, Tim and the conveyor should have separate
                        KPI data, so you can compare the two and establish what
                        needs to be improved
                      </p>

                      <li>Implement Your Desired Maintenance Practices:</li>
                      <p>
                        {" "}
                        You’ve now determined what Tim, Richard, Kelsey, and
                        Robert need to do to improve performance. You’ve also
                        analyzed the KPIs of their equipment and established
                        that maintenance needs to be done more often than you
                        initially thought. From here, you’ll need to put all the
                        different elements into one basket and have open
                        communication with everyone involved to establish common
                        ground. This gives each individual a chance to establish
                        what works best in their department, while giving you
                        key insights into each operation. These meetings are
                        essential to keep everyone in-the-loop about your goals
                        and their expected performance. So, take advantage of
                        these types of meetings as often as possible to ensure
                        everyone has their eye on the ball at all times.
                      </p>
                    </List>
                  </div>
                  <div className="mb-5">
                    <Box>
                      <h1>2. Setting the Pace</h1>
                    </Box>
                  </div>
                  <div className="mb-5">
                    <List>
                      <p>
                        You’ve already established team and equipment goals, now
                        you need to set the standard and pace for the future. In
                        other words, you need to create a memo for each
                        department that outlines set standards and how to
                        achieve them.
                      </p>
                      <p>Let’s look at some key aspects:</p>
                      <li>Establishing Criteria:</li>
                      <p>
                        {" "}
                        What does attainment look like in each respective
                        department? Each facility will undoubtedly have
                        different goals and standards, which makes the picture
                        of department success different for Tim as it would be
                        for Richard – who doesn’t work with the conveyor system
                        at all. So, when establishing the different criteria for
                        each department, consider both the facility and overall
                        company’s standards. From here, everyone will have a
                        clear and holistic standard of operation to adhere to.
                      </p>
                      <li>Setting Up the Game Plan:</li>
                      <p>
                        {" "}
                        You can’t expect Tim to perform preventive maintenance
                        on his conveyor, if he has no clue what your specific
                        maintenance standards are. That’s where the guidebook
                        comes into play. Clearly set up various guidelines
                        accordingly to ensure that Tim knows the what’s, why’s,
                        and how’s of his preventive maintenance tasks. Also,
                        make sure each department’s set of standards are
                        realistic and achievable!
                      </p>
                      <li>Reiterate Your Requirements:</li>
                      <p>
                        {" "}
                        If you’re running a tight ship, chances are you have
                        specific methods and approaches that need to be
                        followed. Oftentimes, people don’t adhere to these
                        methods because they have no idea why they’re there in
                        the first place. So, be sure to reiterate how you want
                        things done, why you want them done in such a way, and
                        what it means for the company to have them done like
                        that.
                      </p>
                    </List>
                  </div>
                  <div className="mb-5">
                    <Box>
                      <h1>3. Turning Idea into Action</h1>
                    </Box>
                  </div>
                  <div className="mb-5">
                    <List>
                      <p>
                        Well done – you’ve made it to the halfway mark! You’ve
                        successfully completed the theory phase, and now, you
                        need to successfully integrate your plan across the
                        board. Here’s how!
                      </p>
                      <li>Propelling the Tight Ship with Methodology: </li>
                      <p>
                        {" "}
                        How will you go about optimizing your team, resources,
                        tasks, and workflows to bring optimal value to the end
                        user? The principles of lean methodology are based on
                        sustainable maintenance and consideration for those
                        involved. In other words, you want harmony between the
                        customer’s needs and the team’s standards..
                      </p>
                      <li>Knowledge is Power:</li>
                      <p>
                        {" "}
                        While cliché, the notion of fueling knowledge can only
                        benefit the business and its teams. Providing key
                        training to your teams will inevitably help them uphold
                        your standards and handle more complex tasks with
                        confidence. At the end of the day, knowledge is power,
                        your team is your company’s backbone, and investing in
                        both will undoubtedly result in success.
                      </p>
                    </List>
                  </div>
                  <div className="mb-5">
                    <Box>
                      <h1>4. Track & Measure</h1>
                      <p>
                        Tracking progress is probably the most important part of
                        the process. If you’re not monitoring performance,
                        you’ve basically done all of the legwork for nothing.
                        You need to implement a concrete tracking strategy to
                        determine whether your newly integrated maintenance
                        practices are actually working or not. You can do this
                        via policy deployment matrix, which relates to aligning
                        employee goals with company goals, and scheduling
                        routine tracking and measurement procedures to
                        continuously ensure everything is running as it should
                        be.
                      </p>
                    </Box>
                  </div>{" "}
                  <div className="mb-5">
                    <Box>
                      <h1>5. Automated Maintenance Management Software</h1>
                      <p>
                        Human error is the leading cause for concern. It’s only
                        natural for both you and your employees to miss
                        something along the way, which is why an automated
                        system is the perfect solution
                      </p>
                      <p>
                        With our integrated CMMS software, you can continually
                        track, analyze, and compare pending and completed tasks
                        across your organization. This way, maintenance tasks
                        will have automated scheduling, and you can keep all
                        communication channels wide open at all times.
                      </p>
                    </Box>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </BoxStyled>
      </PageWrapper>
    </>
  );
};
export default AssetsBestPractices;
